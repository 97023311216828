.alert-component {
  width: 100%;

  .ui.message {
    &.alert-component-message {
      display: flex !important;
      flex-direction: row-reverse !important;
      justify-content: space-between !important;
      align-items: center !important;
      padding: 1rem !important;

      .content {
        width: 100%;
        padding: 0 2.5em 0 0.5em !important;
      }

      .icon.close {
        position: absolute !important;
        top: 0.85rem !important;
        right: 1rem !important;
      }
    }
  }
}
