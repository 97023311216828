@import "../../shared-components/styles/sizes";

$logoVerticalPaddingSize: 10px;

.header-component {
  background-color: white;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);

  .header-top-component {
    flex: 0 1 auto;

    .header-top-inner {
      margin: 0 !important;
      width: 100%;
      height: $menuTopHeight;
      .menu-top-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        justify-content: flex-end;
        width: 87.5%;
      }
    }
  }

  .header-component-grid {
    height: $menuHeight;

    // Remove default margin of the grid.
    margin: 0 !important;

    // Remove default padding of the grid.
    > * {
      padding: 0 !important;
    }

    .header-component-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: $menuHeight;
    }
  }

  .header-component-div-logo {
    height: $menuHeight - $logoVerticalPaddingSize;
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}
