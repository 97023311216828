@import "../shared-components/styles/sizes";

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main-page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .notifications-container {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .menu-container {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1000;
    }

    .main-content {
      background-color: white;
      display: flex;
      flex-grow: 2;
      width: 100%;
    }
  }
}

// Add an ugly border
// Useful to distinct environments
.main-layout-bordered {
  padding: 1rem;
  background: url("../shared-components/img/qa_border.png");
}
