// Responsive
$small-mobile-width: 480px;
$mobile-width: 768px;
$small-screen-width: 1024px;

// Font
$desktop-font-size: 1rem; // 16px
$mobile-font-size: 0.875rem; // 14px

// Header
$header-height: 4rem;
$header-mobile-height: 3.43rem;

// Menu navigation (SideMenu)
$menu-navigation-mobile-height: 4rem;
$menu-navigation-width: 17rem;

// Footer
$footer-height: 2.5rem;

:export {
  headerHeight: $header-height;
  headerMobileHeight: $header-mobile-height;
  mobileFontSize: $mobile-font-size;
}
